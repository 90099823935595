import * as Yup from 'yup'

Yup.setLocale({
  mixed: {
    default: 'Campo inválido',
    required: 'Campo obrigatório'
  },
  string: {
    min: params => `Deve ter pelo menos ${params.min} caracteres`
  }
})
