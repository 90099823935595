import React, { useEffect } from 'react'

import AUTH_TOKEN from './constants/authToken'

// https://github.com/reach/router/issues/100

const Redirect = props => {
  useEffect(() => {
    const redirect = () => {
      setTimeout(() => props.navigate(props.to, { replace: true }), 0)
    }
    redirect()
  }, [props])
  return null
}

const token = () => localStorage.getItem(AUTH_TOKEN)

export const OnlyAuthenticated = ({children, ...props}) => {
  if (token()) {
    return children.props.children({name: 'me'}, props)
  }
  return (props.navigate ? <Redirect navigate={props.navigate} to='/login' /> : null)
}

export const OnlyUnauthenticated = ({children, ...props}) => {
  if (token()) {
    return (props.navigate ? <Redirect navigate={props.navigate} to='/' /> : null)
  }
  return children.props.children(props)
}











// const QueryMe = ({ children, shouldRenderChildren, ...props }) => {
//   // const { data, error, loading } = useQuery(ME)
  
//   // if (error) return `Erro! ${error.message}`
//   // if (!data || loading) return <CircularProgress color='hsla(199, 75%, 43%, 1)' />
  
//   const data = {
//     me: ''
//   }

//   if (data.me) {
//     if (shouldRenderChildren) return children.props.children(data.me, props)
//     return <Redirect navigate={props.navigate} to='/' />
//   }
//   return null
// }

// export const OnlyAuthenticated = ({ children, ...props }) => {
//   if (localStorage.getItem(AUTH_TOKEN))
//     return (<QueryMe shouldRenderChildren={true} children={children} navigate={props.navigate} />)
//   return <Redirect navigate={props.navigate} to='/login' />
// }

// export const OnlyUnauthenticated = ({ children, ...props }) => {
//   // if (localStorage.getItem(AUTH_TOKEN))
//     // return (<QueryMe shouldRenderChildren={false} children={children} navigate={props.navigate} />)
//   return children.props.children(props)
// }
