import React from 'react'
import styled from 'styled-components'
import { Box } from '@alobato/flex-box'

export const FormError = styled.div`
  font-size: 10px;
  line-height: 18px;
  color: hsla(6, 66%, 47%, 1);
  text-transform: uppercase;
  font-weight: 500;
`

export default ({ errors, touched, fieldName }) => (
  <Box minHeight={18}>
    {errors[fieldName] && touched[fieldName] && <FormError>{errors[fieldName]}</FormError>}
  </Box>
)
