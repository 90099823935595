import React, { memo } from 'react'
import { Box } from '@alobato/flex-box'

const LoginLayout = ({ children }) => (
  <Box full overflow='auto'>

    <Box ta='center' pt={[3, 4]}>
      <img alt='tria' src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAH0AAAAqCAMAAACp1aRYAAAAwFBMVEUAAAAAAP//AAAAqv+/AACZZswas+bmAE3fAEDiAE4utuTdAEXqACA+rODfEkncNFvdAEg8ruPfHErgHEc7rOE8rN/fGkg8reHhGUk/reE9rOLhF0c+rOHhFEk7ruE+reLfE0nhEkg9reE9reHgEEk7reHgF0g+rOHgF0g9reHgF0g9rOHgFkk9ruE+reHgFkhCrOA9reHfFUk9reHgFUk9reE9r+Q+sOY/sec/s+hCrODgFUnhFUniFUnkFUvmFUsMp7FVAAAANXRSTlMAAQIDBAUKChAaHCUwMTg7PEhIU1ZZWV1lZmlveH6Hj5CgorGzwcXP19vk5u/x+Pj6+/z9/iqSIZkAAARcSURBVFjDtZjrQuo6EIUntAcEdW9RUUAR5WK5ltN7ewrt+7/Vzq20aRK2cHT9EZrA50wnM6sAGABDaxvo5cRzmMeOvOBt1/O3WwADAZjw6+CnqZ+619CAilAD2qPPje+uZv0G3ibKQB0rjoJL6FhRHMy7+EtO0KG/ybI0TQ9ZtriT8B07drxL6Z4TxPHEAKOhoZvwnu9dnyy5bpbd1/FWbAfBpXSy7CRWE/5R0014yF18nck97NpiZp7131ulRyd22clUQ0eAFpmbHrXLP8Tg56HtVHQMqJQdEbqwq7bdSYagpJtwz+A47fTPfnOF/6VSXlJVUX5xRVFigZVEsUJJSPleuG5q6B+5S6l5znKfPwnBD4YVPU8iUoDhdtB7rqgL3d6jQr3xlt0RJ+7Bb5mO4Gqz92nIL+9kOXWzmVT2pbqJQ0OpXUa6/R2L4p14oqKb8ERDd/M+wIzcAz8V686oqMlqkCQSVa8bwq6qUHMb4mx50VKVeZMjD5tWUQFu/qIL3oBeQdeHW00JasIryRa+V80biY673A5fwURS6a0V2eBmn+KZu5xOPnEb0jL9r3OT1ekm9FniSZMhbYedvWsN/iJ6oKU34JMmPluYgEy4y2jd5SNN6i+hd1nV/yvTG3DNT9k749HG42YL+F+ZR0fhepzyT8hVZ8KIHfbDL0Kvvb2ATk6CuDCgDYI0Y4mOymARTcXN3q+m4kw6jrR2SDvjkLfaV+m885lb3mhUlMHKVMZ2mo7Zze7b1FqWcpJi0txKsfMi91OfXylS78pz9q90ZEBnvI6TOIrCowKHhz6RpgwCc5EJBxxXoV8e/7PoCMHAxkMFyyvFLUa07Uj0srn1C1ZxAg+1Qfd3ugHjJNB5ILsrz3c+3mhjR/Xu86AKXk83SEf1dO5jKjsrVHTWvBxqZeednUU/NlTRdzhF5uXYsaXKpDjNyqBD59Cn3IB5ccWLxIHHgrckT4tBuXSPqxPX/DIdlztznl4QzV9LTcPQY97mtlZ1CNrUV4j1XbgN9aDT04/esyeaEZviSbMR6cJ4g7rT8n1XMej09HHCLMxQ9Bl4xni87ur0Ge9rrYZZqkUMts5j6Ol8lizJua/0AINZbSeyRDpuLC6r7lGdcWxB6Ot0+jRBWpohH0RqrMSqM+GFz7PRw5Oge9pwsM+8kVJ/gk5DTN4k+pDZ0KV44vhAIanPa6JzTukxLqAPVHQ8TI8PT3Wxxyo8ds/J/Fn0YpqdkJ9Jz7PfRecm6pQUHuOb6IWBxOdaKbp0WLWkWfJNdGae/fSgFL/zdY/xTXQ+3vAD80opnvqPH6IXvmIEVy1ZJvMY5GH6R+jcV5CXshq8E5GH6Z+gl4PMRLKqHuPrdM+zVfQYLzgi/dQQr3qMTbtOT+ivBzI9IQ42GSs6LfG28RrTM9ZGrpmvSPdKA1P8kkQbzqj2ZY+RjeVJ9MnSwlq/SvTHNVlYTjE93WG5u/bVxiWvtL9SYI+x8smO9PMPynz9GhqHbnMAAAAASUVORK5CYII=' />
    </Box>

    <Box p={[3, 4]}>
      {children}
    </Box>
  </Box>
)

export default memo(LoginLayout)
