import React, { memo } from 'react'
import { Flex, Box, css } from '@alobato/flex-box'
import Text from '@alobato/text'
import useFetch from '@alobato/use-fetch'
import CircularProgress from '@alobato/circular-progress'
import { Formik } from 'formik'
import * as Yup from 'yup'
import '../../validators'
import { CloseIcon } from '../../components/Icons'

import Form from './Form'

import AUTH_TOKEN from '../../constants/authToken'
import BASE_API from '../../constants/baseApi'

const formDataFromObject = object => Object.keys(object).reduce((formData, key) => {
  formData.append(key, object[key])
  return formData
}, new FormData())

const validationSchema = Yup.object().shape({
  name: Yup.string().required()
})

const Edit = memo(({ id, data, onRequestClose, onUpdated }) => {
  const initialValues = { name: data.name, login: data.login, password: data.password }

  const handleSubmit = async (variables, actions) => {
    try {
      const body = formDataFromObject(variables)
      await (await fetch(`${BASE_API}/establishment?id=${id}`, { method: 'PUT', body, headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}` }})).json()
      actions.setSubmitting(false)
      onUpdated()
      onRequestClose()
    } catch(e) {
      actions.setSubmitting(false)
      console.error(e)
    }
  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Flex bg='grey100' minHeight={64} ai='center'>
        <Box w={64} />
        <Box flex={1} ta='center' css={css`white-space: nowrap; overflow: hidden; text-overflow: ellipsis;`}>
          <Text medium>Editar Estabelecimento</Text>
        </Box>
        <Box w={64} lh={0} ta='center'><Box onClick={onRequestClose} d='inline-block' cursor='pointer' p={1}><CloseIcon /></Box></Box>
      </Flex>
      <Box p={4} overflow='auto'>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          render={props => (<Form {...props} />)}
        />

      </Box>
    </Flex>
  )
})

export default memo(props => {
  const { data, error, loading } = useFetch(`${BASE_API}/establishment?id=${props.id}`, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress color='hsla(199, 75%, 43%, 1)' />
  console.log('data', data)
  return <Edit {...props} data={data} />
})
