import React from 'react'
import styled from 'styled-components';
import DatePicker, {
  registerLocale,
  CalendarContainer
} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

const Container = styled.div`
input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border-radius: 3px;
  height: 35px;
  padding: 0px 30px 0px 10px;
  color: inherit;
  background-image: url(data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2232%22%20height%3D%2232%22%3E%3Cpath%20fill%3D%22grey%22%20d%3D%22M16.59%208.59L12%2013.17%207.41%208.59%206%2010l6%206%206-6z%22%2F%3E%3C%2Fsvg%3E),linear-gradient(to bottom,#ffffff 0%,#ffffff 100%);
  background-repeat: no-repeat,repeat;
  background-position: right .3em top 50%,0 0;
  background-size: 1.25em auto,100%;
  cursor: pointer;
  border: 1px solid hsla(203,20%,76%,1);
  width: 100%;
  max-width: 138px;
  margin: 0;
  -webkit-appearance: none;
}
`;

const MyDatePicker = ({ ...rest }) => {
  registerLocale('ptBR', ptBR)
  return (
    <Container>
    <DatePicker
      dateFormat={'dd/MM/yyyy'}
      locale="ptBR"
      {...rest}
      />
    </Container>
  )
}

export default MyDatePicker

