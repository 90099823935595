import React, { memo } from 'react'
import { Flex, Box, css } from '@alobato/flex-box'
import Text from '@alobato/text'
import useFetch from '@alobato/use-fetch'
import CircularProgress from '@alobato/circular-progress'
import { CloseIcon } from '../../components/Icons'
import Label from '../../components/Label'

import AUTH_TOKEN from '../../constants/authToken'
import BASE_API from '../../constants/baseApi'

const Show = memo(({ data, onRequestClose }) => {

  return (
    <Flex flexDirection='column' h='100%'>
      <Flex bg='grey100' minHeight={64} ai='center'>
        <Box w={64} />
        <Box flex={1} ta='center' css={css`white-space: nowrap; overflow: hidden; text-overflow: ellipsis;`}>
          <Text medium>Detalhes Estabelecimento</Text>
        </Box>
        <Box w={64} lh={0} ta='center'><Box onClick={onRequestClose} d='inline-block' cursor='pointer' p={1}><CloseIcon /></Box></Box>
      </Flex>
      <Box p={4} overflow='auto'>

        <Box mb={3}>
          <Label>ID</Label>
          <Box>{data.id}</Box>
        </Box>

        <Box mb={3}>
          <Label>Nome</Label>
          <Box>{data.name}</Box>
        </Box>

        <Box mb={3}>
          <Label>Email</Label>
          <Box>{data.login}</Box>
        </Box>

      </Box>
    </Flex>
  )
})

export default memo(props => {
  const { data, error, loading } = useFetch(`${BASE_API}/establishment?id=${props.id}`, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress color='hsla(199, 75%, 43%, 1)' />
  console.log('data', data)
  return <Show {...props} data={data} />
})
