import React, { memo } from 'react'
import { Box } from '@alobato/flex-box'
import Input from '@alobato/input'
import Button from '@alobato/button'
import FormErrorBox from '../../components/FormErrorBox'

export default memo(({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => {
  return (
    <form onSubmit={handleSubmit}>
      <Box mb={2}>
        <Input type='email' autoComplete='email' placeholder='Email' name='username' value={values.username} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='username' errors={errors} touched={touched} />
      </Box>
      <Box mb={1}>
        <Input type='password' autoComplete='current-password' placeholder='Senha' name='password' value={values.password} onChange={handleChange} onBlur={handleBlur} />
        <FormErrorBox fieldName='password' errors={errors} touched={touched} />
      </Box>
      <Box ta='center'>
        <Button type='submit' loading={isSubmitting}>Login</Button>
      </Box>
    </form>
  )
})
