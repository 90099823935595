import React, { memo, useState, useEffect, useRef } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import Input from '@alobato/input'
import Select from '@alobato/select'
import Text from '@alobato/text'
import Fab from '@alobato/fab'
import CircularProgress from '@alobato/circular-progress'
import Modal from '../../components/StyledModal'
import Card from '../../components/Card'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import useDebounce from '@alobato/use-debounce'
import useFetch from '../../useFetch'

import List from './List'
import New from './New'
import Edit from './Edit'
import Show from './Show'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

const PAGE_SIZE = 30

const LIST_URL = `${BASE_API}/allDoctors/`
const SPECIALTIES_LIST_URL = `${BASE_API}/getAllSpecialties/`

const Home = memo(({ specialties, page, setPage, data: { results, count }, id, path, navigate, notifier }) => {

  const pageCount = Math.ceil(count > 0 ? count / PAGE_SIZE : 0)

  const [items, setItems] = useState(results)

  const [patientNameQuery, setPatientNameQuery] = useQueryParam('name', StringParam)
  const [name, setName] = useState(patientNameQuery)
  const debouncedName = useDebounce(name, 500)

  const [patientEmailQuery, setPatientEmailQuery] = useQueryParam('email', StringParam)
  const [email, setEmail] = useState(patientEmailQuery)
  const debouncedEmail = useDebounce(email, 500)

  const [specialtyQuery, setSpecialtyQuery] = useQueryParam('specialty', StringParam)
  const [specialty, setSpecialty] = useState(specialtyQuery)

  const [order, setOrder] = useQueryParam('order', StringParam)

  useEffect(() => {
    setPatientNameQuery(debouncedName)
    setName(name)
  }, [debouncedName])

  useEffect(() => {
    setPatientEmailQuery(debouncedEmail)
    setEmail(email)
  }, [debouncedEmail])

  useEffect(() => {
    setSpecialtyQuery(specialty)
  }, [specialty])

  const didMountRef = useRef(false)
  useEffect(() => {
    if (didMountRef.current) {
      setPage(1)
    } else {
      didMountRef.current = true
    }
  }, [patientNameQuery, patientEmailQuery])

  const getItems = async () => {
    try {
      return await (await fetch(LIST_URL, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` }})).json()
    } catch(e) {
      console.error(e)
      return []
    }
  }

  const handleCreated = async () => {
    notifier.success('Criado com sucesso!')
    setItems(await getItems())
  }

  const onUpdated = async () => {
    notifier.success('Salvo com sucesso!')
    setItems(await getItems())
  }

  const handleDeleteItem = async id => {
    if (!window.confirm('Você tem certeza que deseja excluir?')) return false
    try {
      const data = await (await fetch(`${BASE_API}/doctor?id=${id}`, { method: 'DELETE', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}` }})).json()
      if (data.worked) {
        notifier.success('Excluído com sucesso!')
        setItems(await getItems())
      }
    } catch(e) {
      console.error(e)
    }
  }

  const handlePageChange = ({ selected }) => {
    setPage((selected || 0) + 1)
  }

  return (
    <>
      <Box p={[0, 4]} mb={5}>
      
        <Card p={[3, 4]}>

          <Flex jc='space-between' mb={4} ai='center'>
            <Box><Text large fw={700}>Médicos</Text></Box>
          </Flex>

          <Box mb={3} bgColor='grey100' borderRadius={4}>
            <Flex flexWrap='wrap' ai='center'>
              <Box mr={3} my={1} minWidth={270}><Input type='search' placeholder='Nome' value={name} onChange={e => setName(e.target.value)} /></Box>
              <Box mr={3} my={1} minWidth={270}><Input type='search' placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} /></Box>
              <Box mr={3} my={1} minWidth={270}>
                <Select style={{width: "100%"}} placeholder='Especialidades' value={specialty} options onChange={e => setSpecialty(e.target.value)}>
                  {specialties.map(item => (
                    <option value={item.name}>{item.name}</option>
                  ))}
                </Select>
              </Box>
              <Box mr={3} my={1} minWidth={270}>
                <Select value={order} onChange={e => setOrder(e.target.value)}>
                  <option value={''}>Ordernar por...</option>
                  <option value={'name'}>Ordernar por Nome (A-Z)</option>
                  <option value={'-name'}>Ordernar por Nome (Z-A)</option>
                  <option value={'created_at'}>Ordernar por Criação (menor-maior)</option>
                  <option value={'-created_at'}>Ordernar por Criação (maior-menor)</option>
                </Select>
              </Box>
            </Flex>
          </Box>

          <List onPageChange={handlePageChange} pageCount={pageCount} currentPage={page} items={items} navigate={navigate} path={path} deleteItem={handleDeleteItem} />

        </Card>

      </Box>

      <Fab onClick={() => navigate('novo')} />

      {path === '/novo' &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate('../')}
          render={
            ({ onRequestClose }) => (
              <New onRequestClose={onRequestClose} onCreated={handleCreated} />
            )
          }
        />
      }

      {path === '/:id/editar' && id &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate('../../')}
          render={
            ({ onRequestClose }) => (
              <Edit onRequestClose={onRequestClose} id={id} onUpdated={onUpdated} />
            )
          }
        />
      }

      {path === '/:id' && id &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate('../')}
          render={
            ({ onRequestClose }) => (
              <Show onRequestClose={onRequestClose} id={id} />
            )
          }
        />
      }

    </>
  )
})

export default memo(props => {
  const { data: specialtiesData, error: specialtiesError, loading: specialtiesLoading } = useFetch(`${SPECIALTIES_LIST_URL}?type=linked`, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } }, [])

  const [page, setPage] = useQueryParam('page', NumberParam)
  const [name, setName] = useQueryParam('name', StringParam)
  const [email, setEmail] = useQueryParam('email', StringParam)
  const [specialty, setSpecialty] = useQueryParam('specialty', StringParam)
  const [order, setOrder] = useQueryParam('order', StringParam)
  let nameParams = ''
  let emailParams = ''
  let specialtyParams = ''
  let orderParams = ''
  let pageParams = `page=${(page || 1)}`
  if (name) nameParams = `&name=${name}`
  if (email) emailParams = `&email=${email}`
  if (specialty) specialtyParams = `&specialty=${specialty}`
  if (order) orderParams = `&order_by=${order || 'name'}`

  const { data, error, loading } = useFetch(`${LIST_URL}?${pageParams}${nameParams}${emailParams}${specialtyParams}${orderParams}`, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } }, [nameParams, emailParams, specialtyParams, pageParams, orderParams])

  // const [page, setPage] = useQueryParam('page', NumberParam)
  // const { data, error, loading } = useFetch(`${LIST_URL}?page=${(page || 1)}`, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })
  if (error || specialtiesError) return `Erro! ${error.message}`
  if (!data || loading || !specialtiesData || specialtiesLoading) return <CircularProgress color='hsla(199, 75%, 43%, 1)' />
  return <Home {...props} data={data} page={page} specialties={specialtiesData} setPage={setPage} />
})
