import React, { memo, useState } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { useQueryParam, NumberParam } from 'use-query-params'

import Card from '../../components/Card'
import CircularProgress from '@alobato/circular-progress'

import List from './List'
// import New  from './New'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

import useFetch from '../../useFetch'

const PAGE_SIZE = 30
const LIST_URL = `${BASE_API}/solicitation/`

const Home = memo(({page, setPage, data: {results, count}, id, path, navigate }) => {
  
  const pageCount = Math.ceil(count > 0 ? count / PAGE_SIZE : 0)
  const [items, setItems] = useState(results)

  const getItems = async () => {
    try {
      return await (await fetch(`${LIST_URL}`, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` }})).json()
    } catch(e) {
      console.error(e)
      return []
    }
  }

  const handlePageChange = ({ selected }) => {
    setPage((selected || 0) + 1)
  }

  return (
    <Box p={[0, 4]} mb={5}>
    
      <Card p={[3, 4]}>

        <Flex jc='space-between' mb={4} ai='center'>
          <Box><Text large fw={700}>Solicitações</Text></Box>
          {/* <Box display={['none', 'block']}><Button onClick={() => navigate('novo')}>Novo</Button></Box> */}
        </Flex>

        <List onPageChange={handlePageChange} pageCount={pageCount} currentPage={page} data={items} navigate={navigate} path={path} id={id} />

      </Card>

    </Box>
  )
})

export default memo(props => {
  const [page, setPage] = useQueryParam('page', NumberParam)
  let pageParams = `&page=${(page || 1)}`

  const {data, error, loading} = useFetch(
    `${LIST_URL}?${pageParams}`, 
    { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } }
  )

  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress color='hsla(199, 75%, 43%, 1)' />

  return <Home {...props} data={data} page={page} setPage={setPage} />
})