import React, { memo, useState, useEffect } from 'react'
import { Flex, Box, css } from '@alobato/flex-box'
import Text from '@alobato/text'
import Input from '@alobato/input'
import Select from '@alobato/select'
import CircularProgress from '@alobato/circular-progress'
import useFetch from '@alobato/use-fetch'

import Button from '../../components/StyledButton'
import InputDate from '../../components/InputDate'
import Label from '../../components/Label'
import { CloseIcon } from '../../components/Icons'
import ReactFileBase64 from '../../components/ReactFileBase64'

// import DEFAULT_HEADERS from '../../constants/defaultHeaders'
import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

const asyncReduce = async (arr, fn, val, pure) => {
  for (let i = 0; i < arr.length; i++) {
    let v = await fn(val, arr[i], i, arr)
    if (pure !== false) val = v
  }
  return val
}

const formDataFromObject = object => Object.keys(object).reduce((formData, key) => {
  formData.append(key, object[key])
  return formData
}, new FormData())

const formUrlEncodedFromObject = object => Object.keys(object).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`).join('&')

const Edit = memo(({ id, data, onCompleted, onRequestClose }) => {

  console.log('data', data)
  
  let examDateInitial = ''
  if (data.exam_obj && data.exam_obj.exam_date) {
    examDateInitial = data.exam_obj.exam_date
    examDateInitial = examDateInitial.slice(0, 10)
  }

  const [removedMediasIds, setRemovedMediasIds] = useState([])

  let currentMediaIds = []
  if (data.exam_obj) currentMediaIds = data.exam_obj.medias.map(item => item.id)
  const [initialMediasIds, setInitialMediasIds] = useState(currentMediaIds)

  // const [base64Images, setBase64Images] = useState('')
  const [sending, setSending] = useState(false)

  const [name, setName] = useState(data.exam ? data.exam.name : '')
  const [examDate, setExamDate] = useState(examDateInitial)
  const [base64Files, setBase64Files] = useState([])

  const [status, setStatus] = useState(data.status || '')
  const [error, setError] = useState('')

  const [fieldsDisabled, setFieldsDisabled] = useState(false)

  const [exams, setExams] = useState([{ name: '', examDate: '', files: [] }])
  console.log('exams', exams)

  useEffect(() => {
    
    if (status === 'CONCLUÍDA COM ERRO') {
      setFieldsDisabled(true)
    } else {
      setFieldsDisabled(false)
    }

  }, [status])

  const item = data

  const handleUpload = (files, index) => {

    const tempFiles = files.map(item => ({name: item.name, size: item.size, type: item.type, base64: item.base64}))
    exams[index].files = [...exams[index].files, ...tempFiles]

    // if (files.length > 0) {
    //   files.forEach(file => {
    //     setBase64Files(currentBase64Files => ([...currentBase64Files, {name: file.name, size: file.size, type: file.type, base64: file.base64}]))
    //   })
    //   // setBase64Images(files.map(item => item.base64))
    // }

  }

  const handleRemoveMediaId = id => {
    setRemovedMediasIds(currentItem => ([...currentItem, id]))
  }

  const handleRemoveThumb = index => {
    setBase64Files(currentBase64Files => {
      return currentBase64Files.filter((item, i) => (i !== index))
    })
  }

  const handleSend = async () => {
    setSending(true)

    console.log('exams', exams)

    for await (let exam of exams) {

      console.log('exam', exam)

      const base64Images = exam.files.map(item => item.base64)
      let mediasIds = await asyncReduce(base64Images, async (acc, value) => {
        const formData = new FormData()
        formData.append('image', value)
        const data = await (await fetch(`${BASE_API}/media/`, { method: 'POST', body: formData, headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })).json()
        acc.push(data.id)
        return acc
      }, [])

      console.log('mediasIds', mediasIds)

      const saveExamResult = await (await fetch(`${BASE_API}/solicitation/${id}/save_exam/`, { method: 'PUT', body: formUrlEncodedFromObject({ ids: `[${mediasIds.join(',')}]`, name: exam.name, exam_date: `${exam.examDate}T00:00` }), headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`, 'Content-Type': 'application/x-www-form-urlencoded'} })).json()
      console.log('saveExamResult', saveExamResult)
    }

    const updateSolicitationResult = await (await fetch(`${BASE_API}/solicitation/${id}/`, { method: 'PUT', body: formDataFromObject({ status, error }), headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })).json()
    console.log('updateSolicitationResult', updateSolicitationResult)

    // let newMediasIds = []
    // if (removedMediasIds.length > 0) {
    //   newMediasIds = initialMediasIds.filter(item => {
    //     if (removedMediasIds.includes(item)) return false
    //     return true
    //   })
    // } else {
      // newMediasIds = initialMediasIds
    // }

    // let params = {
    //   // ids: JSON.stringify([...newMediasIds, ...mediasIds]),
    //   ids: mediasIds,
    //   name,
    //   status,
    //   error,
    //   exam_date: `${examDate}T00:00`
    // }

    // if (status === 'CONCLUÍDA COM ERRO') {
    //   params = {
    //     status,
    //     error
    //   }
    // }

    // const body = formUrlEncodedFromObject(params)
    // const result = await (await fetch(`${BASE_API}/solicitation/${id}/save_exam/`, { method: 'PUT', body, headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}`, 'Content-Type': 'application/x-www-form-urlencoded'} })).json()

    // const body = formDataFromObject(params)
    // const result = await (await fetch(`${BASE_API}/solicitation/${id}/`, { method: 'PUT', body, headers: {...DEFAULT_HEADERS} })).json()

    setSending(false)

    // if (result) {
      onCompleted()
      onRequestClose()
    // }

  }

  const handleAddExam = () => {
    if(fieldsDisabled === false){
      setExams(currentExams => {
        return [...currentExams, { name: '', examDate: '', files: [] }]
      })
    }
  }

  const handleChangeExamName = (value, index) => {
    setExams(currentExams => {
      const newExams = [...currentExams]
      newExams[index].name = value
      return newExams
    })
  }

  const handleChangeExamDate = (value, index) => {
    setExams(currentExams => {
      const newExams = [...currentExams]
      newExams[index].examDate = value
      return newExams
    })
  }

  const readFile = file => {
    return new Promise(resolve => {
      const fr = new FileReader()
      fr.onload = () => resolve(fr.result)
      fr.readAsDataURL(file)
    })
  }

  const handleChangeFiles = async (files, index) => {
    let filesList = await asyncReduce(files, async (acc, file) => {
      const result = await readFile(file)
      return [ ...acc, { name: file.name, type: file.type, size: Math.round(file.size / 1000) + ' kB', base64: result, file: file } ]
    }, [])
    
    setExams(currentExams => {
      const newExams = [...currentExams]
      newExams[index].files = [...newExams[index].files, ...filesList]
      return newExams
    })
  }

  return (
    <Flex flexDirection='column' h='100%'>
      <Flex bg='grey100' minHeight={64} ai='center'>
        <Box w={64} />
        <Box flex={1} ta='center' fontWeight={600} color='hsla(199, 75%, 57%, 1)' css={css`white-space: nowrap; overflow: hidden; text-overflow: ellipsis;`}><Text medium>Editar</Text></Box>
        <Box w={64} lh={0} ta='center'><Box onClick={onRequestClose} d='inline-block' cursor='pointer' p={1}><CloseIcon /></Box></Box>
      </Flex>
      <Box p={4} >

        <Box mb={3}>
          <Label>ID</Label>
          <Box><Text fw={300}>{item.id}</Text></Box>
        </Box>

        <Box my={4} style={{borderBottom: '1px solid lightgray'}} />

        {exams.map((item, idx) => console.log('idx', idx) || (
          <Box key={idx}>

            <Box mb={3}>
              <Box><Label>Upload</Label></Box>
              {!fieldsDisabled &&
                <Box lh={1}>
                  <input disabled={fieldsDisabled} type='file' id={`file${idx}`} className='inputfile' onChange={e => handleChangeFiles(e.target.files, idx)} multiple={true} />
                  <label htmlFor={`file${idx}`}>Selecione os arquivos</label>
                </Box>
              }
            </Box>

            {/*data.exam_obj &&
              <Box my={4}>
                {data.exam_obj.medias.map(item => {
                  if (!removedMediasIds.includes(item.id))
                    return (
                      <Flex key={item.id}>
                        <Box onClick={() => handleRemoveMediaId(item.id)} d='inline-block' cursor='pointer' p={1}><CloseIcon height={16} /></Box>
                        <Box key={item.id}><a href={item.image} target='_blank' rel='noopener noreferrer'>{`${item.image}`}</a></Box>
                      </Flex>
                    )
                  return null
                })}
              </Box>
            */}
            
            <Box mb={3}>
              <Label>Nome</Label>
              <Box><Input disabled={fieldsDisabled} autoComplete='nope' value={exams[idx].name} onChange={e => handleChangeExamName(e.target.value, idx)} /></Box>
            </Box>

            <Box mb={3}>
              <Label>Data do Exame</Label>
              <Box><InputDate disabled={fieldsDisabled} autoComplete='nope' value={examDate} defaultValue={examDateInitial} onChange={(event, value, maskedValue) => handleChangeExamDate(value, idx)} /></Box>
            </Box>

            <Box maxHeight={200} overflow='auto'>
              {item.files.map((file, i) =>
                <Box key={i} m={2}>
                  <Box>
                    {['image/png', 'image/jpg', 'image/jpeg'].includes(file.type) &&
                      <img height={100} alt='' src={file.base64} />
                    }
                  </Box>
                  {/* <Box onClick={() => handleRemoveThumb(i)} d='inline-block' cursor='pointer' p={1}><CloseIcon height={16} /></Box> */}
                  <Box>{file.name}</Box>
                  <Box>{file.type}</Box>
                  <Box>{file.size}</Box>
                </Box>
              )}
            </Box>

            <Box my={4} style={{borderBottom: '1px solid lightgray'}} />

          </Box>
        ))}

        <Box>
          <Button onClick={handleAddExam}>Adicionar</Button>
        </Box>

        <Box my={4} style={{borderBottom: '1px solid lightgray'}} />

        <Box mb={3}>
          <Label>Status</Label>
          <Box>
            <Select placeholder='Selecione...' value={status} onChange={e => { setStatus(e.target.value); setError('') }}>
              <option>CONCLUÍDA</option>
              <option>CONCLUÍDA COM ERRO</option>
            </Select>
          </Box>
        </Box>

        {status === 'CONCLUÍDA COM ERRO' &&
          <Box mb={3}>
            <Label>Erro</Label>
            <Box>
              <Select placeholder='Selecione...' value={error} onChange={e => setError(e.target.value)}>
                <option>Login ou senha incorretos</option>
                <option>Não achamos a clínica mencionada</option>
                <option>Não há novos exames</option>
              </Select>
            </Box>
          </Box>
        }

        <Box mb={3}>
          <Box lh={1}><Button loading={sending} onClick={handleSend}>Salvar</Button></Box>
        </Box>

      </Box>
    </Flex>
  )
})

export default memo(props => {
  const { data, error, loading } = useFetch(`${BASE_API}/solicitation/${props.id}/`, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress color='hsla(199, 75%, 43%, 1)' />
  return <Edit {...props} data={data} />
})
