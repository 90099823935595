import styled from 'styled-components/macro'
import Button from '@alobato/button'

export default styled(Button)`
  font-family: inherit;
  border-radius: 36px;
  background: transparent;
  border: 2px solid #df1c4a;
  color: #df1c4a;
  line-height: 0;
  min-width: 100px;
  padding: 12px;
  margin-top: 20px;
  font-size: 16px;
  &:hover {
    background: #df1c4a;
    color: white;
  }
`
