import styled from 'styled-components/macro'
import { Box } from '@alobato/flex-box'

export default styled(Box)`
  background-color: ${props => props.theme.colors.white};
  .desktop & {
    box-shadow: rgba(0,0,0,.16) 0 3px 6px;
  }
`





