import React, { memo, useState, useEffect, useRef } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import Input from '@alobato/input'
import Select from '@alobato/select'
import Text from '@alobato/text'
import Fab from '@alobato/fab'
import useFetch from '../../useFetch'
import CircularProgress from '@alobato/circular-progress'
import Modal from '../../components/StyledModal'
import Card from '../../components/Card'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import useDebounce from '@alobato/use-debounce'

import List from './List'
import New from './New'
import Edit from './Edit'
import Show from './Show'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

const PAGE_SIZE = 30

const LIST_URL = `${BASE_API}/allPatientsV2/`

const Home = memo(({ page, setPage, data: { results, count }, id, path, navigate, notifier }) => {

  const pageCount = Math.ceil(count > 0 ? count / PAGE_SIZE : 0)

  const [items, setItems] = useState(results)

  const [patientNameQuery, setPatientNameQuery] = useQueryParam('name', StringParam)
  const [name, setName] = useState(patientNameQuery)
  const debouncedName = useDebounce(name, 500)

  const [patientEmailQuery, setPatientEmailQuery] = useQueryParam('email', StringParam)
  const [email, setEmail] = useState(patientEmailQuery)
  const debouncedEmail = useDebounce(email, 500)

  const [order, setOrder] = useQueryParam('order', StringParam)

  useEffect(() => {
    setPatientNameQuery(debouncedName)
    setName(name)
  }, [debouncedName])

  useEffect(() => {
    setPatientEmailQuery(debouncedEmail)
    setEmail(email)
  }, [debouncedEmail])

  const didMountRef = useRef(false)
  useEffect(() => {
    if (didMountRef.current) {
      setPage(1)
    } else {
      didMountRef.current = true
    }
  }, [patientNameQuery, patientEmailQuery])

  const getItems = async () => {
    try {
      return await (await fetch(`${LIST_URL}?storage=false`, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` }})).json()
    } catch(e) {
      console.error(e)
      return []
    }
  }

  const handleCreated = async () => {
    notifier.success('Criado com sucesso!')
    setItems(await getItems())
  }

  const onUpdated = async () => {
    notifier.success('Salvo com sucesso!')
    setItems(await getItems())
  }

  const handleDeleteItem = async id => {
    if (!window.confirm('Você tem certeza que deseja excluir?')) return false
    try {
      const data = await (await fetch(`${BASE_API}/patient?id=${id}`, { method: 'DELETE', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}` }})).json()
      if (data.worked) {
        notifier.success('Excluído com sucesso!')
        setItems(await getItems())
      }
    } catch(e) {
      console.error(e)
    }
  }

  const handlePageChange = ({ selected }) => {
    setPage((selected || 0) + 1)
  }

  console.log('name', name)

  return (
    <>
      <Box p={[0, 4]} mb={5}>
      
        <Card p={[3, 4]}>

          <Flex jc='space-between' mb={4} ai='center'>
            <Box><Text large fw={700}>Pacientes</Text></Box>
          </Flex>

          <Box mb={3} bgColor='grey100' borderRadius={4}>
            <Flex flexWrap='wrap' ai='center'>
              <Box mr={3} my={1} minWidth={270}><Input type='search' placeholder='Nome' value={name} onChange={e => setName(e.target.value)} /></Box>
              <Box mr={3} my={1} minWidth={270}><Input type='search' placeholder='Email' value={email} onChange={e => setEmail(e.target.value)} /></Box>
              <Box mr={3} my={1} minWidth={270}>
                <Select value={order} onChange={e => setOrder(e.target.value)}>
                  <option value={''}>Ordernar por...</option>
                  <option value={'name'}>Ordernar por Nome (A-Z)</option>
                  <option value={'-name'}>Ordernar por Nome (Z-A)</option>
                  <option value={'created_at'}>Ordernar por Criação (menor-maior)</option>
                  <option value={'-created_at'}>Ordernar por Criação (maior-menor)</option>
                </Select>
              </Box>
            </Flex>
          </Box>

          <List onPageChange={handlePageChange} pageCount={pageCount} currentPage={page} items={items} navigate={navigate} path={path} deleteItem={handleDeleteItem} />

        </Card>

      </Box>

      <Fab onClick={() => navigate('novo')} />

      {path === '/novo' &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate('../')}
          render={
            ({ onRequestClose }) => (
              <New onRequestClose={onRequestClose} onCreated={handleCreated} />
            )
          }
        />
      }

      {path === '/:id/editar' && id &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate('../../')}
          render={
            ({ onRequestClose }) => (
              <Edit onRequestClose={onRequestClose} id={id} onUpdated={onUpdated} />
            )
          }
        />
      }

      {path === '/:id' && id &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate('../')}
          render={
            ({ onRequestClose }) => (
              <Show onRequestClose={onRequestClose} id={id} />
            )
          }
        />
      }

    </>
  )
})

export default memo(props => {
  const [page, setPage] = useQueryParam('page', NumberParam)
  const [name, setName] = useQueryParam('name', StringParam)
  const [email, setEmail] = useQueryParam('email', StringParam)
  const [order, setOrder] = useQueryParam('order', StringParam)
  let nameParams = ''
  let emailParams = ''
  let orderParams = ''
  let pageParams = `&page=${(page || 1)}`
  if (name) nameParams = `&name=${name}`
  if (email) emailParams = `&email=${email}`
  if (order) orderParams = `&order_by=${order}`
  
  // const { data, error, loading } = useQuery(LIST, { fetchPolicy: 'cache-and-network', variables: { limit: PAGE_SIZE, offset, search, clinicName, modality, specialty, examination, from, to, orderBy: (order || 'id desc') } })
  const { data, error, loading } = useFetch(`${LIST_URL}?storage=false&${pageParams}${nameParams}${emailParams}${orderParams}`, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } }, [nameParams, emailParams, pageParams, orderParams])
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress color='hsla(199, 75%, 43%, 1)' />
  return <Home {...props} data={data} page={page} setPage={setPage} />
})





// export default memo(props => {
//   const [search, setSearch] = useQueryParam('search', StringParam)
//   const [clinicName] = useQueryParam('clinicName', StringParam)
//   const [modality] = useQueryParam('modality', StringParam)
//   const [specialty] = useQueryParam('specialty', StringParam)
//   const [examination] = useQueryParam('examination', StringParam)
//   const [from] = useQueryParam('from', StringParam)
//   const [to] = useQueryParam('to', StringParam)
//   const [order] = useQueryParam('order', StringParam)
//   const [page, setPage] = useQueryParam('page', NumberParam)
//   const offset = page ? ((page - 1) * PAGE_SIZE) : 0
//   const { data, error, loading } = useQuery(LIST, { fetchPolicy: 'cache-and-network', variables: { limit: PAGE_SIZE, offset, search, clinicName, modality, specialty, examination, from, to, orderBy: (order || 'id desc') } })
//   if (error) return `Erro! ${error.message}`
//   if (loading || !data) return <CircularProgress />
//   return <List {...props} data={data} page={page} setPage={setPage} offset={offset} search={search} setSearch={setSearch} clinicName={clinicName} modality={modality} specialty={specialty} examination={examination} from={from} to={to} order={order} />
// })