import React, { memo, useState } from 'react'
import { css } from 'styled-components/macro'

import { Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { Table, TableContainer, TableRow, TableCell, TableHeaderRow, TableHeaderCell } from '@alobato/table'
import Pagination from '../../components/Pagination'

import MenuOver from '../../components/MenuOver'
import { MoreIcon } from '../../components/Icons'
import Modal from '../../components/StyledModalOverflow'

import Show from './Show'
import Edit from './Edit'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

const LIST_URL = `${BASE_API}/solicitation/`

const formatDateTime = text => {
  const year = text.slice(0, 4)
  const month = text.slice(5, 7)
  const day = text.slice(8, 10)
  return `${day}/${month}/${year}`
}

const List = ({ data, navigate, path, id, onPageChange, currentPage, pageCount }) => {

  const [items, setItems] = useState(data)

  const getItems = async () => {
    try {
      return await (await fetch(LIST_URL, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` }})).json()
    } catch(e) {
      console.error(e)
      return []
    }
  }

  const handleMenuOverClick = (action, id) => {
    switch (action) {
      case 'show':
        navigate(id.toString())
        break
      case 'edit':
        navigate(`/solicitacoes/${id}/editar`)
        break
      default:
        return false
    }
  }

  const completedSolicitations = items => items.filter(item => item.status.includes('CONCLUÍDA'))

  const pendingSolicitations = items => items.filter(item => item.status.includes('PENDENTE'))

  return (
    <>
      <Box mb={3}>
        <Text medium fw={600}>Pendentes</Text>
      </Box>

      <Box mb={5}>
        {!!pendingSolicitations(items).length ? (
          <TableContainer>
            <Table>
              <TableHeaderRow>
                <TableHeaderCell>ID</TableHeaderCell>
                <TableHeaderCell>Data/Hora</TableHeaderCell>
                <TableHeaderCell>Cidade</TableHeaderCell>
                <TableHeaderCell>Estabelecimento</TableHeaderCell>
                <TableHeaderCell>Email</TableHeaderCell>
                <TableHeaderCell>Login</TableHeaderCell>
                <TableHeaderCell>Senha</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
              </TableHeaderRow>
              {pendingSolicitations(items).map(item => (
                <TableRow key={item.id}>
                  <TableCell data-title='ID'>{item.id}</TableCell>
                  <TableCell data-title='Data/Hora'>{formatDateTime(item.created_at)}</TableCell>
                  <TableCell data-title='Cidade'>{item.city}</TableCell>
                  <TableCell data-title='Estabelecimento'>{((item.establishment && item.establishment.name) || item.establishment_name)}</TableCell>
                  <TableCell data-title='Email'>{item.user.login}</TableCell>
                  <TableCell data-title='Login'>{item.login}</TableCell>
                  <TableCell data-title='Senha'>{item.password}</TableCell>
                  <TableCell data-title='Status'>{item.status}</TableCell>
                  <TableCell width={40} lineHeight={0}>
                    <MenuOver
                      options={[{label: 'Editar', value: 'edit'}]}
                      onSelect={action => handleMenuOverClick(action, item.id)}
                    >
                      <MoreIcon style={{cursor: 'pointer'}} />
                    </MenuOver>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        ) : (
          <Box mt={5} ta='center' css={css`@keyframes fadein { 0% { opacity:0 } 66% { opacity:0 } 100% { opacity:1 } } & { animation: 2s ease 0s normal forwards 1 fadein; }`}>
            <Text medium fw={300} color='grey400'>Nenhum registro encontrado</Text>
          </Box>
        )}
      </Box>
  

      <Box mb={3}>
        <Text medium fw={600}>Concluídas</Text>
      </Box>

      <Box mb={5}>
        {!!completedSolicitations(items).length ? (
          <TableContainer>
            <Table>
              <TableHeaderRow>
                <TableHeaderCell>ID</TableHeaderCell>
                <TableHeaderCell>Data/Hora</TableHeaderCell>
                <TableHeaderCell>Cidade</TableHeaderCell>
                <TableHeaderCell>Estabelecimento</TableHeaderCell>
                <TableHeaderCell>Email</TableHeaderCell>
                <TableHeaderCell>Login</TableHeaderCell>
                <TableHeaderCell>Senha</TableHeaderCell>
                <TableHeaderCell>Status</TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
              </TableHeaderRow>
              {completedSolicitations(items).map(item => (
                <TableRow key={item.id}>
                  <TableCell data-title='ID'>{item.id}</TableCell>
                  <TableCell data-title='Data/Hora'>{formatDateTime(item.created_at)}</TableCell>
                  <TableCell data-title='Cidade'>{item.city}</TableCell>
                  <TableCell data-title='Estabelecimento'>{((item.establishment && item.establishment.name) || item.establishment_name)}</TableCell>
                  <TableCell data-title='Email'>{item.user.login}</TableCell>
                  <TableCell data-title='Login'>{item.login}</TableCell>
                  <TableCell data-title='Senha'>{item.password}</TableCell>
                  <TableCell data-title='Status'>{item.status}</TableCell>
                  <TableCell width={40} lineHeight={0}>
                    <MenuOver
                      options={[{label: 'Detalhes', value: 'show'}, {label: 'Editar', value: 'edit'}]}
                      onSelect={action => handleMenuOverClick(action, item.id)}
                    >
                      <MoreIcon style={{cursor: 'pointer'}} />
                    </MenuOver>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        ) : (
          <Box mt={5} ta='center' css={css`@keyframes fadein { 0% { opacity:0 } 66% { opacity:0 } 100% { opacity:1 } } & { animation: 2s ease 0s normal forwards 1 fadein; }`}>
            <Text medium fw={300} color='grey400'>Nenhum registro encontrado</Text>
          </Box>
        )}
      </Box>

      <Box ta='center'>
        <Pagination onPageChange={onPageChange} currentPage={currentPage} pageCount={pageCount} />
      </Box>

      {path === '/:id/editar' && id &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate('../../')}
          render={
            ({onRequestClose}) => (
              <Edit
                id={id}
                onCompleted={async () => {
                  const newItems = await getItems()
                  setItems(newItems.results)
                }}
                onRequestClose={onRequestClose}
              />
            )
          }
        />
      }

      {path === '/:id' && id &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate('../')}
          render={
            ({onRequestClose}) => (
              <Show
                id={id}
                onCompleted={async () => {
                  const newItems = await getItems()
                  setItems(newItems.results)
                }}
                onRequestClose={onRequestClose}
              />
            )
          }
        />
      }
    </>
  )
}

export default memo(List)