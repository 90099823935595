import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { AppProvider } from './AppContext'

ReactDOM.render(<AppProvider><App /></AppProvider>, document.getElementById('root'))

serviceWorker.unregister()


