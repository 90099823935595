import React, { memo } from 'react'
import { Box } from '@alobato/flex-box'
import Input from '@alobato/input'
import Button from '@alobato/button'
import Label from '../../components/Label'
import FormErrorBox from '../../components/FormErrorBox'

const Form = ({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, isValid, setFieldValue }) => {
  return (
    <form autoComplete='nope' onSubmit={handleSubmit}>

      <Box mb={2}>
        <Label>Nome</Label>
        <Input autoComplete='nope' name='name' value={values.name} onChange={handleChange} onBlur={handleBlur} withError={errors.name} />
        <FormErrorBox fieldName='name' errors={errors} touched={touched} />
      </Box>

      <Box ta='right'>
        <Button type='submit' loading={isSubmitting}>Salvar</Button>
      </Box>

    </form>
  )
}

export default memo(Form)
