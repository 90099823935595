import React, { memo } from 'react'
import { css } from 'styled-components/macro'
import { Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { Table, TableContainer, TableRow, TableCell, TableHeaderRow, TableHeaderCell } from '@alobato/table'
import MenuOver from '../../components/MenuOver'
import { MoreIcon } from '../../components/Icons'
import Pagination from '../../components/Pagination'

const mapGender = gender => {
  if (!gender) return ''
  if (gender === 'Feminino') return 'F'
  if (gender === 'Masculino') return 'M'
}

const formatDateTime = text => {
  if (!text) return ''
  const year = text.slice(0, 4)
  const month = text.slice(5, 7)
  const day = text.slice(8, 10)
  return `${day}/${month}/${year}`
}

const List = ({ items, navigate, deleteItem, onPageChange, currentPage, pageCount }) => {

  const handleMenuOverClick = (action, id) => {
    switch (action) {
      case 'delete':
        deleteItem(id)
        break
      case 'show':
        navigate(`${id}`)
        break
      default:
        return false
    }
  }

  return (
    <>
      <Box mb={5}>
        {!!items.length ? (
          <TableContainer>
            <Table>
              <TableHeaderRow>
                <TableHeaderCell>Nome</TableHeaderCell>
                <TableHeaderCell>Sexo</TableHeaderCell>
                <TableHeaderCell>Email</TableHeaderCell>
                <TableHeaderCell>Dt. Nascimento</TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
              </TableHeaderRow>
              {items.map(item => (
                <TableRow key={item.id}>
                  <TableCell data-title='Nome'>{item.name}</TableCell>
                  <TableCell data-title='Sexo'>{mapGender(item.gender)}</TableCell>
                  <TableCell data-title='Email'>{item.login}</TableCell>
                  <TableCell data-title='Dt. Nascimento'>{formatDateTime(item.birth_date)}</TableCell>
                  <TableCell width={40} lineHeight={0}>
                    <MenuOver
                      options={[{label: 'Detalhes', value: 'show'}, {label: 'Excluir', value: 'delete'}]}
                      onSelect={action => handleMenuOverClick(action, item.id)}
                    >
                      <MoreIcon style={{cursor: 'pointer'}} />
                    </MenuOver>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        ) : (
          <Box mt={5} ta='center' css={css`@keyframes fadein { 0% { opacity:0 } 66% { opacity:0 } 100% { opacity:1 } } & { animation: 2s ease 0s normal forwards 1 fadein; }`}>
            <Text medium fw={300} color='grey400'>Nenhum registro encontrado</Text>
          </Box>
        )}
      </Box>

      <Box ta='center'>
        <Pagination onPageChange={onPageChange} currentPage={currentPage} pageCount={pageCount} />
      </Box>
    </>
  )
}

export default memo(List)
