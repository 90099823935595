import React, { memo, useEffect, useState } from 'react'
// import { Bar } from 'react-chartjs-2'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'

const monthMap = { 0: 'Jan', 1: 'Fev', 2: 'Mar', 3: 'Abr', 4: 'Mai', 5: 'Jun', 6: 'Jul', 7: 'Ago', 8: 'Set', 9: 'Out', 10: 'Nov', 11: 'Dez' }

export default memo(({ id, chartData }) => {

  console.log('chartData', chartData)

  return (
    <BarChart
      width={900}
      height={300}
      data={chartData}
      margin={{top: 5, right: 30, left: 20, bottom: 5}}
      style={{margin: '0 auto', marginTop: 50}}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey='Usuários conectados' fill="#8884d8" />
      <Bar dataKey='Exames distribuídos' fill="#82ca9d" />
      <Bar dataKey='Exames visualizados' fill="#95bde0" />
      <Bar dataKey='Usuários sincronizados automaticamente' fill="#ebc182" />
    </BarChart>
  )
})
