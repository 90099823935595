import React, { memo, useContext, useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import { Router, globalHistory } from '@reach/router'
import { QueryParamProvider } from 'use-query-params'
import useMobileDetect from '@alobato/use-mobile-detect'
import notifier from 'simple-react-notifications'

import { AppContext } from './AppContext'
import GlobalStyle from './globalStyle'
import theme from './theme'

import AUTH_TOKEN from './constants/authToken'
import { OnlyUnauthenticated, OnlyAuthenticated } from './authorization'

import Dashboard from './containers/Dashboard'
import Solicitations from './containers/Solicitations'
import Doctors from './containers/Doctors'
import Establishments from './containers/Establishments'
import Patients from './containers/Patients'
import Specialities from './containers/Specialities'
import Plans from './containers/Plans'
import Login from './containers/Sessions/Login'

const App = () => {
  
  useMobileDetect()

  const { state, setItemState } = useContext(AppContext)

  notifier.configure({ autoClose: 2000, position: 'top-center', delay: 500, single: false, width: '480px' })

  useEffect(() => {
    if (state.notification) {
      notifier.success(state.notification)
      setItemState({notification: ''})
    }
  }, [setItemState, state]) 

  const logout = () => {
    localStorage.removeItem(AUTH_TOKEN)
    window.location.href = '/login'
    setItemState({currentUser: null})
  }

  console.log('v1.2')

  return (
    <ThemeProvider theme={theme}>
      <QueryParamProvider reachHistory={globalHistory}>

        <GlobalStyle />
        
        <Router>
          <OnlyUnauthenticated path='/login'>
            {props => <Login {...props} notifier={notifier} />}
          </OnlyUnauthenticated>

          <OnlyAuthenticated path='/'>
            {props => <Dashboard {...props} onLogout={logout} notifier={notifier} />}
          </OnlyAuthenticated>

          <OnlyAuthenticated path='/planos'>
            {props => <Plans {...props} onLogout={logout} notifier={notifier} />}
          </OnlyAuthenticated>

          <OnlyAuthenticated path='/solicitacoes'>
            {props => <Solicitations {...props} onLogout={logout} notifier={notifier} />}
          </OnlyAuthenticated>

          <OnlyAuthenticated path='/medicos'>
            {props => <Doctors {...props} onLogout={logout} notifier={notifier} />}
          </OnlyAuthenticated>

          <OnlyAuthenticated path='/estabelecimentos'>
            {props => <Establishments {...props} onLogout={logout} notifier={notifier} />}
          </OnlyAuthenticated>

          <OnlyAuthenticated path='/pacientes'>
            {props => <Patients {...props} onLogout={logout} notifier={notifier} />}
          </OnlyAuthenticated>

          <OnlyAuthenticated path='/especialidades'>
            {props => <Specialities {...props} onLogout={logout} notifier={notifier} />}
          </OnlyAuthenticated>
        </Router>

      </QueryParamProvider>
    </ThemeProvider>
  )
}

export default memo(App)
