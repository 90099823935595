import React, { memo, useState, useEffect } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import Select from '@alobato/select'
import { Bar } from 'react-chartjs-2'
import useFetch from '@alobato/use-fetch'
import CircularProgress from '@alobato/circular-progress'
import Card from '../../components/Card'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

import * as moment from 'moment'

const LIST_URL = `${BASE_API}/statistics/`

const options = {
  barValueSpacing: 20,
  responsive: true,
  maintainAspectRatio: false,
  layout: {
    padding: {
      left: 0,
      right: 5,
      top: 10,
      bottom: 5
    }
  },
  scales: {
    yAxes: [{
      ticks: {
        fontSize: 12, fontFamily: 'Montserrat, sans-serif', fontColor: '#000', fontStyle: '500', beginAtZero: true,
        padding: 0,
      }
    }],
    xAxes: [{
      categoryPercentage: 0.8,
      barPercentage: 0.7,
      // barThickness: 20,
      ticks: { fontSize: 12, fontFamily: 'Montserrat, sans-serif', fontColor: '#000', fontStyle: '500' },
      gridLines: {
        tickMarkLength: 20,
        offsetGridLines: true
      },
      scaleLabel: {
        lineHeight: 10,
        padding: 120
      }
    }]
  },
  legend: {
    labels: {
      fontColor: '#000',
      fontSize: 15,
      padding: 10
    },
    position: 'bottom'
  }
}

const chartData = {
  labels: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul'],
  datasets: [
    {
      label: 'Médicos',
      data: [10, 50, 25, 70, 40],
      backgroundColor: [
        '#3FAFE4', '#3FAFE4', '#3FAFE4', '#3FAFE4', '#3FAFE4', '#3FAFE4', '#3FAFE4'
      ],
      borderWidth: 0,
    },
    {
      label: 'Estabelecimentos',
      data: [10, 50, 25, 70, 40],
      backgroundColor: [
        '#DF1C4A', '#DF1C4A', '#DF1C4A', '#DF1C4A', '#DF1C4A', '#DF1C4A', '#DF1C4A'
      ],
      borderWidth: 0,
    },
    {
      label: 'Pacientes',
      data: [20, 35, 40, 60, 50],
      backgroundColor: [
        '#F87BCE', '#F87BCE', '#F87BCE', '#F87BCE', '#F87BCE', '#F87BCE', '#F87BCE'
      ],
      borderWidth: 0
    }
  ]
}

const Home = memo(({ data, id, path, navigate, notifier }) => {

  const [period, setPeriod] = useState('1')
  const [currentChartData, setCurrentChartData] = useState(chartData)

  useEffect(() => {

    const getChart = async (period) => {

      let initialDate = ''
      const finalDate = moment(new Date())
      let jsonData = {}

      switch (period) {
        case '1':
          initialDate = `${(new Date()).getFullYear()}-${(new Date()).getMonth()}-01`

          initialDate = moment().subtract(30, 'days')

          jsonData = await (await fetch(`${BASE_API}/filteredStatistics/?initial_date=${initialDate.format('YYYY-MM-DD')}&final_date=${finalDate.format('YYYY-MM-DD')}`, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` }})).json()
          
          if (jsonData.detail && jsonData.detail === 'Token expired.') {
            localStorage.removeItem(AUTH_TOKEN)
            navigate('/')
          }

          const doctorsLength = jsonData.registered_doctors.length
          const establishmentsLength = jsonData.registered_establishments.length
          const patientsLength = jsonData.registered_patients.length
      
          const foundChartData1 = {
            labels: [`${initialDate.format('DD/MM/YYYY')}-${finalDate.format('DD/MM/YYYY')}`],
            datasets: [
              {
                label: 'Médicos',
                data: [doctorsLength],
                backgroundColor: ['#3FAFE4'],
                borderWidth: 0,
              },
              {
                label: 'Estabelecimentos',
                data: [establishmentsLength],
                backgroundColor: ['#DF1C4A'],
                borderWidth: 0,
              },
              {
                label: 'Pacientes',
                data: [patientsLength],
                backgroundColor: ['#F87BCE'],
                borderWidth: 0
              }
            ]
          }
          setCurrentChartData(foundChartData1)
          break
        case '3':
        case '6':

          const months = parseInt(period)
          const dates = []
          const doctors = []
          const establishments = []
          const patients = []
          initialDate = moment().subtract(months, 'months')
          jsonData = await (await fetch(`${BASE_API}/filteredStatistics/?initial_date=${initialDate.format('YYYY-MM-DD')}&final_date=${finalDate.format('YYYY-MM-DD')}`, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` }})).json()
      
          for(var i = 0; i < months; i++){
            dates.push(moment().subtract(i, 'months'))
            doctors.push(jsonData.registered_doctors.filter(item => item.created_at.includes(dates[i].format('YYYY-MM'))).length)
            establishments.push(jsonData.registered_establishments.filter(item => item.created_at.includes(dates[i].format('YYYY-MM'))).length)
            patients.push(jsonData.registered_patients.filter(item => item.created_at.includes(dates[i].format('YYYY-MM'))).length)
          }
    
          const foundChartData3 = {
            labels: dates.map(item => item.format('MM/YYYY')).reverse(),
            datasets: [
              {
                label: 'Médicos',
                data: doctors.reverse(),
                backgroundColor: ['#3FAFE4', '#3FAFE4', '#3FAFE4', '#3FAFE4', '#3FAFE4', '#3FAFE4'],
                borderWidth: 0,
              },
              {
                label: 'Estabelecimentos',
                data: establishments.reverse(),
                backgroundColor: ['#DF1C4A', '#DF1C4A', '#DF1C4A', '#DF1C4A', '#DF1C4A', '#DF1C4A'],
                borderWidth: 0,
              },
              {
                label: 'Pacientes',
                data: patients.reverse(),
                backgroundColor: ['#F87BCE', '#F87BCE', '#F87BCE', '#F87BCE', '#F87BCE', '#F87BCE'],
                borderWidth: 0
              }
            ]
          }
          setCurrentChartData(foundChartData3)
          break

        default:
          return false
      }
    }

    getChart(period)

  }, [period])

  return (
    <>
      <Box p={[0, 4]} mb={5}>
      
        <Card p={[3, 4]}>

          <Flex jc='space-between' mb={4} ai='center'>
            <Box><Text large fw={700}>Dashboard</Text></Box>
          </Flex>

          <Flex jc='flex-end' mb={4}>
            <Box w={300}>
              <Select value={period} onChange={e => setPeriod(e.target.value)}>
                <option value={1}>Últimos 30 dias</option>
                <option value={3}>Últimos 3 meses</option>
                <option value={6}>Últimos 6 meses</option>
              </Select>
            </Box>
          </Flex>

          <Box h={500}>
            {chartData &&
              <Bar data={currentChartData} options={options} />
            }
          </Box>

          <div style={{display: 'flex', justifyContent: 'space-between', margin: '80px 40px'}}>

            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 150, height: 150, border: '9px solid #F87BCE', borderRadius: '50%'}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: 80, height: 80}}>
                <div style={{textAlign: 'center', fontSize: 24, fontFamily: 'Montserrat', fontWeight: 400, color: '#F87BCE'}}>{data.number_of_registered_patients}</div>
                <div style={{textAlign: 'center', fontSize: 14, lineHeight: '16px', fontFamily: 'Montserrat', fontWeight: 300, color: '#F87BCE'}}>Pacientes Cadastrados</div>
              </div>
            </div>

            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 150, height: 150, border: '9px solid #3FAFE4', borderRadius: '50%'}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: 80, height: 80}}>
                <div style={{textAlign: 'center', fontSize: 24, fontFamily: 'Montserrat', fontWeight: 400, color: '#3FAFE4'}}>{data.number_of_registered_doctors}</div>
                <div style={{textAlign: 'center', fontSize: 14, lineHeight: '16px', fontFamily: 'Montserrat', fontWeight: 300, color: '#3FAFE4'}}>Médicos Cadastrados</div>
              </div>
            </div>

            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 150, height: 150, border: '9px solid #DF1C4A', borderRadius: '50%'}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: 80, height: 80}}>
                <div style={{textAlign: 'center', fontSize: 24, fontFamily: 'Montserrat', fontWeight: 400, color: '#DF1C4A'}}>{data.number_of_registered_establishments}</div>
                <div style={{textAlign: 'center', fontSize: 14, lineHeight: '16px', fontFamily: 'Montserrat', fontWeight: 300, color: '#DF1C4A'}}>Estabelecimentos Cadastrados</div>
              </div>
            </div>

            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 150, height: 150, border: '9px solid #F87BCE', borderRadius: '50%'}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: 80, height: 80}}>
                <div style={{textAlign: 'center', fontSize: 24, fontFamily: 'Montserrat', fontWeight: 400, color: '#F87BCE'}}>{data.number_of_uploaded_images}</div>
                <div style={{textAlign: 'center', fontSize: 14, lineHeight: '16px', fontFamily: 'Montserrat', fontWeight: 300, color: '#F87BCE'}}>Uploads de imagens</div>
              </div>
            </div>

            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 150, height: 150, border: '9px solid #3FAFE4', borderRadius: '50%'}}>
              <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: 80, height: 80}}>
                <div style={{textAlign: 'center', fontSize: 24, fontFamily: 'Montserrat', fontWeight: 400, color: '#3FAFE4'}}>{data.number_of_linked_images}</div>
                <div style={{textAlign: 'center', fontSize: 14, lineHeight: '16px', fontFamily: 'Montserrat', fontWeight: 300, color: '#3FAFE4'}}>Exames sincronizados</div>
              </div>
            </div>

          </div>


        </Card>

      </Box>
    </>
  )
})

export default memo(props => {
  const { data, error, loading } = useFetch(LIST_URL, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress color='hsla(199, 75%, 43%, 1)' />
  return <Home {...props} data={data} />
})
