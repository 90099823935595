import React, { createContext, useState } from 'react'

const AppContext = createContext([{}, () => {}])

const AppProvider = (props) => {
  const [state, setState] = useState({ notification: '', currentUser: null })
  const setItemState = item => setState(currentState => ({...currentState, ...item}))
  return (
    <AppContext.Provider value={{ state, setState, setItemState }}>
      {props.children}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
 