import React, { memo, useState } from 'react'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import Fab from '@alobato/fab'
import useFetch from '@alobato/use-fetch'
import CircularProgress from '@alobato/circular-progress'
import Modal from '../../components/StyledModal'
import Card from '../../components/Card'

import List from './List'
import Edit from './Edit'
import New from './New'

import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

const LIST_URL = `${BASE_API}/getAllSpecialties/`

const Home = memo(({ data, id, path, navigate, notifier }) => {

  const [items, setItems] = useState(data)

  const getItems = async () => {
    try {
      return await (await fetch(LIST_URL, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` }})).json()
    } catch(e) {
      console.error(e)
      return []
    }
  }

  const handleCreated = async () => {
    notifier.success('Criado com sucesso!')
    setItems(await getItems())
  }

  const onUpdated = async () => {
    notifier.success('Salvo com sucesso!')
    setItems(await getItems())
  }

  const handleDeleteItem = async id => {
    if (!window.confirm('Você tem certeza que deseja excluir?')) return false
    try {
      const data = await (await fetch(`${BASE_API}/specialty?id=${id}`, { method: 'DELETE', headers: { Authorization:  `token ${localStorage.getItem(AUTH_TOKEN)}` }})).json()
      if (data.worked) {
        notifier.success('Excluído com sucesso!')
        setItems(await getItems())
      }
    } catch(e) {
      console.error(e)
    }
  }

  return (
    <>
      <Box p={[0, 4]} mb={5}>
      
        <Card p={[3, 4]}>

          <Flex jc='space-between' mb={4} ai='center'>
            <Box><Text large fw={700}>Especialidades</Text></Box>
          </Flex>

          <List items={items} navigate={navigate} path={path} id={id} deleteItem={handleDeleteItem} />

        </Card>

      </Box>

      <Fab onClick={() => navigate('novo')} />

      {path === '/novo' &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate('../')}
          render={
            ({ onRequestClose }) => (
              <New onRequestClose={onRequestClose} onCreated={handleCreated} />
            )
          }
        />
      }

      {path === '/:id/editar' && id &&
        <Modal
          clickOutsideDisabled
          onCloseCompleted={() => navigate('../../')}
          render={
            ({ onRequestClose }) => (
              <Edit onRequestClose={onRequestClose} id={id} onUpdated={onUpdated} />
            )
          }
        />
      }

    </>
  )
})

export default memo(props => {
  const { data, error, loading } = useFetch(LIST_URL, { headers: { Authorization: `token ${localStorage.getItem(AUTH_TOKEN)}` } })
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress color='hsla(199, 75%, 43%, 1)' />
  return <Home {...props} data={data} />
})
