import React, { memo } from 'react'
import { Formik } from 'formik'
import { Flex, Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import md5 from 'md5'
import * as Yup from 'yup'
import '../../validators'
import Card from '../../components/Card'

import LoginForm from './LoginForm'
import LoginLayout from '../../layouts/LoginLayout'

import AUTH_TOKEN from '../../constants/authToken'
import BASE_API from '../../constants/baseApi'

const formDataFromObject = object => Object.keys(object).reduce((formData, key) => {
  formData.append(key, object[key])
  return formData
}, new FormData())

const validationSchema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required()
})

export default memo(({ navigate, notifier }) => {

  const initialValues = { username: '', password: '' }

  const handleSubmit = async (variables, actions) => {
    localStorage.removeItem(AUTH_TOKEN)
    try {
      const body = formDataFromObject({...variables, type: 'admin', password: md5(variables.password).toUpperCase()})
      const data = await (await fetch(`${BASE_API}/auth/`, { method: 'POST', body })).json()
      actions.setSubmitting(false)
      if (data.token) {
        localStorage.setItem(AUTH_TOKEN, data.token)
        navigate('/')
      }
      else {
        notifier.error('Dados inválidos.')
      }
    } catch(e) {
      actions.setSubmitting(false)
      console.error(e)
    }
  }

  return (
    <LoginLayout>
      <Flex jc='center' maxWidth={420} mx='auto'>

        <Box width={[1]}>

          <Card p={[3, 4]} mx='auto'>

            <Box ta='center' mb={4}>
              <Text large fw={700}>Login</Text>
            </Box>

            <Box mx='auto'>
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                validationSchema={validationSchema}
                render={props => (<LoginForm {...props} />)}
              />
            </Box>

          </Card>

        </Box>

      </Flex>
    </LoginLayout>
  )
})
