import styled, { css } from 'styled-components/macro'

export default styled.label`
  font-weight: 600;
  line-height: 24px;
  color: hsla(199, 75%, 57%, 1);
  ${props => props.uppercase && css`text-transform: uppercase;`}
  & > span {
    color: hsla(216, 40%, 70%, 1);
  }
`
