import React, { memo, useState } from 'react'
import { Flex, Box, css } from '@alobato/flex-box'
import Text from '@alobato/text'
import CircularProgress from '@alobato/circular-progress'
import useFetch from '@alobato/use-fetch'

import Label from '../../components/Label'
import { CloseIcon } from '../../components/Icons'

import DEFAULT_HEADERS from '../../constants/defaultHeaders'
import BASE_API from '../../constants/baseApi'
import AUTH_TOKEN from '../../constants/authToken'

const Show = memo(({ id, data, onRequestClose }) => {
  return (
    <Flex flexDirection='column' maxWidth={800}>
      <Flex position='fixed' bg='grey100' minHeight={64} ai='center' width='100%' maxWidth={800}>
        <Box w={64} />
        <Box flex={1} ta='center' fontWeight={600} color='hsla(199, 75%, 57%, 1)' css={css`white-space: nowrap; overflow: hidden; text-overflow: ellipsis;`}>
          <Text medium>Detalhes</Text>
        </Box>
        <Box w={64} lh={0} ta='center'><Box onClick={onRequestClose} d='inline-block' cursor='pointer' p={1}><CloseIcon /></Box></Box>
      </Flex>
      <Box p={4} overflow='auto'>

        <Box mb={3}>
          <Label>ID</Label>
          <Box><Text fw={300}>{data.id}</Text></Box>
        </Box>

        <Box mb={3}>
          <Label>Estabelecimento</Label>
          <Box><Text fw={300}>{((data.establishment && data.establishment.name) || data.establishment_name)}</Text></Box>
        </Box>

        <Box mb={3}>
          <Label>Cidade</Label>
          <Box><Text fw={300}>{data.city}</Text></Box>
        </Box>

        <Box mb={3}>
          <Label>Login</Label>
          <Box><Text fw={300}>{data.login}</Text></Box>
        </Box>

        <Box mb={3}>
          <Label>Senha</Label>
          <Box><Text fw={300}>{data.password}</Text></Box>
        </Box>

        <Box mb={3}>
          <Label>Status</Label>
          <Box><Text fw={300}>{data.status}</Text></Box>
        </Box>

        <Box mb={3}>
          <Label>Exames</Label>
          {data.exams &&
            data.exams.map(item => {
              return (
                <Box key={item.id}>
                  <Box><Text fw={300}>Data do exame: {item.formated_date}</Text></Box>
                  <Box><Text fw={300}>Nome: {item.name}</Text></Box>
                  <Box>
                    {item.medias.map(item => (
                      <Box key={item.id}><a href={`${item.image}?token=token%20${localStorage.getItem(AUTH_TOKEN)}`} target='_blank' rel='noopener noreferrer'>{`${item.image}`}</a></Box>
                    ))}
                  </Box>

                  <br/>
                </Box>
              )
            })
          }
        </Box>

      </Box>
    </Flex>
  )
})

export default memo(props => {
  const { data, error, loading } = useFetch(`${BASE_API}/solicitation/${props.id}/`, { headers: DEFAULT_HEADERS })
  if (error) return `Erro! ${error.message}`
  if (!data || loading) return <CircularProgress color='hsla(199, 75%, 43%, 1)' />
  return <Show {...props} data={data} />
})
