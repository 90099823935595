import React, { memo } from 'react'
import { css } from 'styled-components/macro'
import { Box } from '@alobato/flex-box'
import Text from '@alobato/text'
import { Table, TableContainer, TableRow, TableCell, TableHeaderRow, TableHeaderCell } from '@alobato/table'
import MenuOver from '../../components/MenuOver'
import { MoreIcon } from '../../components/Icons'

const List = ({ items, navigate, deleteItem }) => {

  const handleMenuOverClick = (action, id) => {
    switch (action) {
      case 'delete':
        deleteItem(id)
        break
      case 'edit':
        navigate(`${id}/editar`)
        break
      default:
        return false
    }
  }

  return (
    <>
      <Box mb={5}>
        {!!items.length ? (
          <TableContainer>
            <Table>
              <TableHeaderRow>
                <TableHeaderCell>Nome</TableHeaderCell>
                <TableHeaderCell></TableHeaderCell>
              </TableHeaderRow>
              {items.map(item => (
                <TableRow key={item.id}>
                  <TableCell data-title='Nome'>{item.name}</TableCell>
                  <TableCell width={40} lineHeight={0}>
                    <MenuOver
                      options={[{label: 'Editar', value: 'edit'}, {label: 'Excluir', value: 'delete'}]}
                      onSelect={action => handleMenuOverClick(action, item.id)}
                    >
                      <MoreIcon style={{cursor: 'pointer'}} />
                    </MenuOver>
                  </TableCell>
                </TableRow>
              ))}
            </Table>
          </TableContainer>
        ) : (
          <Box mt={5} ta='center' css={css`@keyframes fadein { 0% { opacity:0 } 66% { opacity:0 } 100% { opacity:1 } } & { animation: 2s ease 0s normal forwards 1 fadein; }`}>
            <Text medium fw={300} color='grey400'>Nenhum registro encontrado</Text>
          </Box>
        )}
      </Box>
    </>
  )
}

export default memo(List)
