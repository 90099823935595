const theme = {
  space: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 96],
  primaryFontSize: '15px',
  primaryLineHeight: '1.5',
  primaryFontFamily: 'Montserrat',
  primaryFontWeight: 400,
  secondaryFontFamily: 'Montserrat',
  colors: {
    white:      'white',
    black:      'black',
    primary100: 'hsla(199, 75%, 96%, 1)',
    primary200: 'hsla(199, 75%, 86%, 1)',
    primary300: 'hsla(199, 75%, 76%, 1)',
    primary400: 'hsla(199, 75%, 66%, 1)',
    primary:    'hsla(199, 75%, 57%, 1)',
    primary600: 'hsla(199, 75%, 50%, 1)',
    primary700: 'hsla(199, 75%, 43%, 1)',
    primary800: 'hsla(199, 75%, 34%, 1)',
    primary900: 'hsla(199, 75%, 28%, 1)',
    grey100:    'hsla(216, 20%, 95%, 1)',
    grey200:    'hsla(  0,  0%, 86%, 1)',
    grey300:    'hsla(  0,  0%, 76%, 1)',
    grey400:    'hsla(  0,  0%, 66%, 1)',
    grey:       'hsla(  0,  0%, 57%, 1)',
    grey600:    'hsla(  0,  0%, 50%, 1)',
    grey700:    'hsla(  0,  0%, 43%, 1)',
    grey800:    'hsla(  0,  0%, 34%, 1)',
    grey900:    'hsla(  0,  0%, 28%, 1)',
    error:      'hsla(  6, 66%, 47%, 1)',
  }
}

export default theme
